import { Controller } from "@hotwired/stimulus"

// We use this to set the user's default timezone on signup
class localeTime extends Controller {
  static targets = ["input"]

  connect() {
    const value = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (value.length > 0) {
      this.inputTarget.value = value
    }
  }
  declare inputTarget: HTMLInputElement
  declare timeValue: string
}

export default localeTime
