import { Controller } from "@hotwired/stimulus"

class SonarSearchForm extends Controller {
  static targets = [
    "searchInput",
    "makeInput",
    "locationInput",
    "distanceInput",
    "yearFilterInput",
    "modelFilterInput",
    // "makeFilterInput",
    "locationFilterInput",
    "distanceFilterInput",
  ]

  connect() {
    ;["keyup"].forEach((event) => {
      this.searchInputTarget.addEventListener(event, (e) => {
        if (e.target != undefined) {
          const searchFilters = this.tokenizeSearchQuery(e.target.value)
          this.applyToFilterInputs(searchFilters)
        }
      })
    })

    // this.makeInputTarget.addEventListener("change", (e) => {
    //   if (e.target != undefined) {
    //     this.makeFilterInputTarget.value = e.target.value
    //   }
    // })

    this.locationInputTarget.addEventListener("change", (e) => {
      if (e.target != undefined) {
        this.locationFilterInputTarget.value = e.target.value
      }
    })
  }

  tokenizeSearchQuery(query: string) {
    const filters: Record<string, any> = {}
    const tokens = query.split(", ")

    tokens.forEach((token) => {
      const [key, value] = this.processToken(token)

      if (key && value) {
        // remove leading and trailing whitespace
        filters[key.trim()] = value.trim()
      }
    })

    return filters
  }

  processToken(token: string) {
    // # Split the tokens into subtokens by colons
    // i.e "year: 1999" => ["year", "1999"]
    const subtokens = token.split(":")

    const key = this.processKey(subtokens[0])
    const value = this.processValue(subtokens[1])

    return [key, value]
  }

  processKey(string: string) {
    // remove any whitespace from the subtokens for keys and downcase
    const key = string.replace(/\s/g, "").toLowerCase()

    if (["year", "model", "distance"].includes(key)) {
      return key
    }

    if ("year".includes(key) || key.includes("year")) return "year"
    if ("model".includes(key) || key.includes("model")) return "model"
    if ("distance".includes(key) || key.includes("distance")) return "distance"

    return null
  }

  processValue(string: string) {
    if (string == undefined) return null
    // remove leading and trailing whitespace for values
    const value = string.trim()
    return value
  }

  applyToFilterInputs(filters: Record<string, any>) {
    this.yearFilterInputTarget.value = filters.year ? filters.year : ""
    this.modelFilterInputTarget.value = filters.model ? filters.model : ""
    this.distanceFilterInputTarget.value = filters.distance ? filters.distance : ""

    this.triggerFilterInputCount(filters)
  }

  triggerFilterInputCount(filters: Record<string, any>) {
    const filtersCount = Object.values(filters).length
    if (filtersCount > 0) {
      const event = new CustomEvent("update-filter-count")
      window.dispatchEvent(event)
    }
  }

  declare searchInputTarget: HTMLElement
  declare makeInputTarget: HTMLInputElement
  declare locationInputTarget: HTMLInputElement

  declare yearFilterInputTarget: HTMLInputElement
  declare modelFilterInputTarget: HTMLInputElement
  // declare makeFilterInputTarget: HTMLInputElement
  declare locationFilterInputTarget: HTMLInputElement
  declare distanceFilterInputTarget: HTMLInputElement
}

export default SonarSearchForm
