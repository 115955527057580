import { Controller } from "@hotwired/stimulus"

class Countdown extends Controller {
  static values = { endtime: String }
  static targets = ["container", "day", "hour", "minute", "second"]

  connect() {
    this.updateClock(
      this.endtimeValue,
      this.dayTarget,
      this.hourTarget,
      this.minuteTarget,
      this.secondTarget,
      this.containerTarget
    )

    const id = setInterval(
      this.updateClock,
      1000,
      this.endtimeValue,
      this.dayTarget,
      this.hourTarget,
      this.minuteTarget,
      this.secondTarget,
      this.containerTarget
    )

    this.containerTarget.dataset.countdownId = id
  }

  updateClock(endtime, dayTarget, hourTarget, minuteTarget, secondTarget, containerTarget) {
    const getTimeRemaining = (endtime) => {
      const total = Date.parse(endtime) - Date.parse(new Date())
      const seconds = Math.floor((total / 1000) % 60)
      const minutes = Math.floor((total / 1000 / 60) % 60)
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
      const days = Math.floor(total / (1000 * 60 * 60 * 24))

      return {
        total,
        days,
        hours,
        minutes,
        seconds,
      }
    }

    const t = getTimeRemaining(endtime)

    dayTarget.innerHTML = t.days + " days"
    hourTarget.innerHTML = ("0" + t.hours).slice(-2) + " hours"
    minuteTarget.innerHTML = ("0" + t.minutes).slice(-2) + " minutes"
    secondTarget.innerHTML = ("0" + t.seconds).slice(-2) + " seconds"

    if (t.days >= 1) {
      dayTarget.classList.remove("hidden")
      hourTarget.classList.remove("hidden")
      minuteTarget.classList.add("hidden")
      secondTarget.classList.add("hidden")
    } else if (t.days < 1) {
      dayTarget.classList.add("hidden")

      if (t.hours >= 1) {
        hourTarget.classList.remove("hidden")
        minuteTarget.classList.remove("hidden")
        secondTarget.classList.add("hidden")
      } else if (t.hours < 1) {
        hourTarget.classList.add("hidden")

        if (t.minutes >= 1) {
          minuteTarget.classList.remove("hidden")
          secondTarget.classList.remove("hidden")
        } else if (t.minutes < 1) {
          minuteTarget.classList.add("hidden")
          secondTarget.classList.remove("hidden")
        }
      }
    }

    if (t.total <= 0) {
      console.log("clearing countdown")
      containerTarget.classList.add("hidden")
      const countdownId = containerTarget.dataset.countdownId
      clearInterval(countdownId)
    }
  }
}

export default Countdown
