import { Controller } from "@hotwired/stimulus"

class InventoryUploadMapper extends Controller {
  static targets = ["inputIdCache", "option"]

  connect() {
    console.log("inventory upload mapper connected")

    this.optionTargets.forEach((elem) => {
      const alreadyMappedInputId = elem.dataset.inventoryUploadMapperMappedTo

      if (alreadyMappedInputId != undefined && elem.dataset.inventoryUploadMapperValue != undefined) {
        // mark option as selected
        this.markSelectedOption(elem, alreadyMappedInputId, true)

        // set input value
        const currentInput = document.getElementById(alreadyMappedInputId)
        this.setInputValue(currentInput, elem.dataset.inventoryUploadMapperValue)

        // replace contents of input's mapped div
        this.setInputMappedDiv(elem, alreadyMappedInputId, true)
      }
    })
  }

  cacheInputId(event) {
    this.inputIdCacheTarget.dataset.inventoryUploadMapperInputId =
      event.currentTarget.dataset.inventoryUploadMapperInputId
  }

  select(event) {
    event.preventDefault()

    const inputId = this.inputIdCacheTarget.dataset.inventoryUploadMapperInputId || ""
    const alreadyMappedInputId = event.currentTarget.dataset.inventoryUploadMapperMappedTo
    const currentInput = document.getElementById(inputId)

    if (alreadyMappedInputId != undefined) {
      if (alreadyMappedInputId !== inputId) {
        const previousInput = document.getElementById(alreadyMappedInputId)
        if (previousInput) {
          // unmap previous input
          this.setInputValue(previousInput, "")
          this.setInputMappedDiv(event.currentTarget, alreadyMappedInputId, false)
        }
        // set input value
        this.setInputValue(currentInput, event.currentTarget.dataset.inventoryUploadMapperValue)
        // replace contents of input's mapped div
        this.setInputMappedDiv(event.currentTarget, inputId, true)
        // mark option as selected
        this.markSelectedOption(event.currentTarget, inputId, true)
      } else {
        // unmap current input if clicked twice
        this.setInputValue(currentInput, "")
        this.setInputMappedDiv(event.currentTarget, inputId, false)
        this.markSelectedOption(event.currentTarget, inputId, false)
      }
    } else {
      // reset an option that was previously selected
      this.resetFormerOption(currentInput)
      // set input value
      this.setInputValue(currentInput, event.currentTarget.dataset.inventoryUploadMapperValue)
      // replace contents of input's mapped div
      this.setInputMappedDiv(event.currentTarget, inputId, true)
      // mark option as selected
      this.markSelectedOption(event.currentTarget, inputId, true)
    }

    // remove cachedid
    delete this.inputIdCacheTarget.dataset.inputId

    // close sidepanel
    this.closeSidePanel()
  }

  resetFormerOption(input: HTMLElement | null) {
    if (input) {
      console.log(input.value)
    }
  }

  markSelectedOption(element: HTMLElement, inputId: string | null, selected: boolean) {
    const mappedOptionClasses = "border-indigo-500"
    const unmappedOptionClasses = "border-gray-200"

    if (selected == true && inputId != undefined) {
      element.classList.remove(unmappedOptionClasses)
      element.classList.add(mappedOptionClasses)
      element.dataset.inventoryUploadMapperMappedTo = inputId
    } else {
      element.classList.add(unmappedOptionClasses)
      element.classList.remove(mappedOptionClasses)
      delete element.dataset.inventoryUploadMapperMappedTo
    }
  }

  setInputValue(input: HTMLElement | null, value: string) {
    if (input == undefined) return

    const existingValue = input.value

    if (existingValue != undefined && existingValue != "" && existingValue !== value) {
      const previousOption = document.querySelector(
        `[data-inventory-upload-mapper-value=${existingValue}]`
      ) as HTMLElement

      if (previousOption != undefined) {
        this.markSelectedOption(previousOption, null, true)
      }
    }

    input.value = value
  }

  setInputMappedDiv(element: HTMLElement, inputId: string, selected: boolean) {
    const str = `inventory-upload-mapper-mapped-input-display-${inputId}`
    const mappedInputDiv = document.getElementById(str)

    if (mappedInputDiv != undefined) {
      if (selected === true) {
        mappedInputDiv.innerHTML = element.innerHTML
      } else {
        mappedInputDiv.innerHTML = ""
      }
    }
  }

  closeSidePanel() {
    const overlay = document.querySelector("[data-side-panel-target='overlay']")
    if (overlay != undefined) {
      overlay.click()
    }
  }

  declare inputIdCacheTarget: HTMLElement
  declare optionTargets: Array<HTMLElement>
}

export default InventoryUploadMapper
