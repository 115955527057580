import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false

window.Stimulus = application

const controllers = import.meta.glob("./*_controller.ts", { eager: true })
registerControllers(application, controllers)

import { Alert, Autosave, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
window.Stimulus.register("alert", Alert)
window.Stimulus.register("autosave", Autosave)
window.Stimulus.register("dropdown", Dropdown)
window.Stimulus.register("modal", Modal)
window.Stimulus.register("tabs", Tabs)
window.Stimulus.register("popover", Popover)
window.Stimulus.register("toggle", Toggle)
window.Stimulus.register("slideover", Slideover)
