import { Controller } from "@hotwired/stimulus"

class OptionalCheckboxInput extends Controller {
  static targets = ["checkbox", "input"]

  connect() {
    console.log("OptionalCheckboxInputController connected")
    this.toggle()
  }

  toggle(event) {
    if (this.checkboxTarget.checked) {
      this.inputTarget.disabled = false
    } else {
      this.inputTarget.disabled = true

      if (event && event.params && event.params.defaultInputValue) {
        this.inputTarget.value = event.params.defaultInputValue.value
      } else {
        // If toggle is called without an event, we try and fetch the default manually
        const defaultInputValueParam = this.checkboxTarget.getAttribute(
          "data-optional-checkbox-input-default-input-value-param"
        )

        if (defaultInputValueParam) {
          const obj = JSON.parse(defaultInputValueParam)
          this.inputTarget.value = obj.value
        } else {
          this.inputTarget.value = ""
        }
      }
    }
  }
}

export default OptionalCheckboxInput
