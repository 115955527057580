import { Controller } from "@hotwired/stimulus"

class SidePanel extends Controller {
  static targets = ["trigger", "panel", "closeButton", "overlay"]

  connect() {
    this.panelTarget.classList.add("hidden")

    this.triggerTargets.forEach((trigger: HTMLElement) => {
      trigger.addEventListener("click", (e) => {
        e.preventDefault()

        if (!this.panelTarget.classList.contains("side-panel-open")) {
          this.openPanel()
        }
      })
    })

    this.closeButtonTarget.addEventListener("click", (e) => {
      e.preventDefault()

      this.closePanel()
    })
  }

  openPanel() {
    this.overlayTarget.classList.remove("hidden")
    this.panelTarget.classList.remove("hidden", "translate-x-full")
    this.panelTarget.classList.add("translate-x-0", "side-panel-open")

    this.overlayTarget.addEventListener("click", (e) => {
      this.closePanel()
    })
  }

  closePanel() {
    this.overlayTarget.classList.add("hidden")
    this.panelTarget.classList.remove("translate-x-0", "side-panel-open")
    this.panelTarget.classList.add("translate-x-full")

    this.overlayTarget.removeEventListener("click", (e) => {
      this.closePanel()
    })
  }

  declare panelTarget: HTMLElement
  declare triggerTarget: HTMLElement
  declare triggerTargets: Array<HTMLElement>
  declare closeButtonTarget: HTMLElement
  declare overlayTarget: HTMLElement
}

export default SidePanel
