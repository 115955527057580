import { Controller } from "@hotwired/stimulus"
//@ts-ignore
import Trix from "trix"

class TrixConfig extends Controller {
  connect() {
    // Prevent file uploads
    document.addEventListener("trix-file-accept", (event) => {
      event.preventDefault()
    })

    document.addEventListener("trix-initialize", (event) => {
      // @ts-ignore
      if (event.target != undefined && event.target.toolbarElement != undefined) {
        // @ts-ignore
        let toolbarElement = event.target.toolbarElement

        this.removeFileUploadButton(toolbarElement)
      }
    })
  }

  removeFileUploadButton = (toolbarElement: HTMLElement) => {
    // @ts-ignore
    let fileUploadButtons = toolbarElement.querySelector("[data-trix-button-group='file-tools']")

    if (fileUploadButtons == null) {
      return
    }

    fileUploadButtons.remove()
  }

  // This is how you add a button to the trix toolbar
  // addButton = (toolbarElement: HTMLElement) => {
  //   Trix.config.blockAttributes.heading2 = {
  //     tagName: "h2",
  //     terminal: true,
  //     breakOnReturn: true,
  //     group: false,
  //   }

  //   // @ts-ignore
  //   let h2ButtonHTML = '<button type="button" data-trix-attribute="heading2" title="Subheading">H2</button>'

  //   // @ts-ignore
  //   let groupElement = (toolbarElement as HTMLElement).querySelector("[data-trix-button-group='block-tools']")

  //   if (groupElement == null) {
  //     return
  //   }

  //   groupElement.insertAdjacentHTML("beforeend", h2ButtonHTML)
  // }
}

export default TrixConfig
