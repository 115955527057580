import "@hotwired/turbo-rails"
import "trix"
import "@rails/actiontext"
import "~/controllers"
import AOS from "aos"

document.addEventListener("DOMContentLoaded", function () {
  AOS.init({
    startEvent: "turbo:load", // if you are using turbo
  })
})
