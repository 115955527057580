import { Controller } from "@hotwired/stimulus"

class Notifications extends Controller {
  static targets = ["markAllAsReadButton", "count", "notificationsContainer", "emptyNotificationsContainer"]

  markAsRead(e) {
    e.preventDefault()
    e.stopPropagation()

    const target = e.currentTarget

    let redirectUrl = target.getAttribute("href")
    let markAsReadPath = target.dataset.notificationMarkAsReadPath
    let csrfToken = document.querySelector("[name='csrf-token']").content

    fetch(markAsReadPath, {
      method: "PUT",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      dataType: "script",
      credentials: "include",
    }).then((resp) => {
      console.log(resp)
      window.location = redirectUrl
    })
  }

  markAllAsRead(e) {
    e.preventDefault()
    e.stopPropagation()

    let url = this.markAllAsReadButtonTarget.dataset.notificationsUrl
    let csrfToken = document.querySelector("[name='csrf-token']").content

    fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
      },
      dataType: "script",
      credentials: "include",
    }).then((resp) => {
      this.emptyNotifications()
    })
  }

  emptyNotifications() {
    let unread_notifications = this.notificationsContainerTarget.querySelectorAll(".notification.unread")
    if (unread_notifications.length) {
      for (let notification of unread_notifications) {
        notification.remove()
      }
    }

    this.emptyNotificationsContainerTarget.classList.remove("hide")

    this.countTarget.innerHTML = ""

    // zero out notifications count in document title
    document.title = document.title.replace(/ *\([^)]*\) */g, "")
  }
}

export default Notifications
