import { Controller } from "@hotwired/stimulus";

type AlertKlass = {
  animateClasses: string;
};

class Alert<AlertKlass> extends Controller {
  connect() {
    this.animateClasses = (this.data.get("animationClass") || "hidden").split(
      " "
    );

    if (this.data.has("autoClose")) {
      setTimeout(() => this.close(), this.data.get("autoClose"));
    }
  }

  close() {
    if (this.element) {
      this.element.classList.add(...this.animateClasses); // add the animation class to hide element from dom
      setTimeout(() => this.element.remove(), 0.5 * 1000); // remove from dom after 1/2 second
    }
  }

  declare animateClasses: string[];
}

export default Alert;
