import { Controller } from "@hotwired/stimulus"

type InputKey = keyof typeof SonarSearchFiltersModal

class SonarSearchFiltersModal extends Controller {
  static targets = [
    "yearFilterInput",
    "modelFilterInput",
    "distanceFilterInput",
    "applyFiltersButton",
    // "makeFilterInput",
    "locationFilterInput",
    "filterCount",
  ]

  connect() {
    this.applyFilterCount()

    this.applyFiltersButtonTarget.addEventListener("click", (e) => {
      const filters = this.filterInputObject()
      const filtersAreEmptyBool = Object.values(filters).every((value) => value === "")

      if (!filtersAreEmptyBool) {
        const queryString = this.stringifySearchQuery(filters)
        this.applyToSearchInput(queryString)
      }

      this.applyFilterCount()
      // this.applyMakeFilter()
      this.applyLocationFilter()
      this.submitSearch()
    })
  }

  filterInputObject() {
    return {
      year: this.yearFilterInputTarget.value,
      model: this.modelFilterInputTarget.value,
      distance: this.distanceFilterInputTarget.value,
    }
  }

  // combine FilterInputObject and AllFiltersInputObject
  allFilterInputsObject() {
    return {
      ...this.filterInputObject(),
      // make: this.makeFilterInputTarget.value,
      location: this.locationFilterInputTarget.value,
    }
  }

  // transform the filter input object into a query string
  stringifySearchQuery(filters) {
    const queryString = Object.entries(filters)
      .filter(([, value]) => value != "")
      .map(([key, value]) => `${key}: ${value}`)
      .join(", ")

    return queryString
  }

  // apply the query string to the search input
  applyToSearchInput(queryString: string) {
    const searchInput = document.getElementById("search") as HTMLInputElement
    searchInput.value = queryString
  }

  // // apply the selected option of makeFilterInput to the make selection of the search form
  // applyMakeFilter() {
  //   const makeSelect = document.getElementById("make") as HTMLSelectElement
  //   makeSelect.options[this.makeFilterInputTarget.selectedIndex].selected = true
  // }

  // apply the selected option of locationFilterInput to the location selection of the search form
  applyLocationFilter() {
    const locationSelect = document.getElementById("location") as HTMLSelectElement
    locationSelect.options[this.locationFilterInputTarget.selectedIndex].selected = true
  }

  submitSearch() {
    const searchForm = document.getElementById("sonar-search-form") as HTMLFormElement
    searchForm.submit()
  }

  countFilters() {
    const filters = this.filterInputObject()
    return Object.values(filters).filter((value) => value != "").length
  }

  // count the number of filters that have been applied
  applyFilterCount() {
    const count = this.countFilters()

    if (count > 0) {
      this.filterCountTarget.textContent = count.toString()
      this.filterCountTarget.classList.remove("hidden")
    } else {
      this.filterCountTarget.classList.add("hidden")
    }
  }

  clearAll(e: Event) {
    e.preventDefault()

    this.yearFilterInputTarget.value = ""
    this.modelFilterInputTarget.value = ""
    this.distanceFilterInputTarget.value = ""
    // this.makeFilterInputTarget.options[0].selected = true
  }

  declare yearFilterInputTarget: HTMLInputElement
  declare modelFilterInputTarget: HTMLInputElement
  declare distanceFilterInputTarget: HTMLInputElement
  // declare makeFilterInputTarget: HTMLSelectElement
  declare locationFilterInputTarget: HTMLSelectElement
  declare applyFiltersButtonTarget: HTMLElement
  declare filterCountTarget: HTMLElement
}

export default SonarSearchFiltersModal
